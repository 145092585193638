import React from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import Title from "../components/title";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

export default function Gallery() {
  // const data = useStaticQuery(graphql`
  //   query {
  //     allInstaNode {
  //       edges {
  //         node {
  //           id
  //           likes
  //           comments
  //           mediaType
  //           preview
  //           original
  //           timestamp
  //           caption
  //           localFile {
  //             childImageSharp {
  //               fixed(width: 250, height: 250) {
  //                 ...GatsbyImageSharpFixed
  //               }
  //             }
  //           }
  //           # Only available with the public api scraper
  //           thumbnails {
  //             src
  //             config_width
  //             config_height
  //           }
  //           dimensions {
  //             height
  //             width
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);
  const data = useStaticQuery(graphql`
    query {
      image: allFile(filter: { relativeDirectory: { eq: "photos" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Container padding>
        <Title top={`GALLERI`} />
        {data.image.edges.map((node, id) => {
          return <Img fluid={node.node.childImageSharp.fluid} key={id}></Img>;
        })}
      </Container>
    </Layout>
  );
}
